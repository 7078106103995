.container {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 10px;
  flex-shrink: 0;
}

.main {
  position: relative;
  user-select: none;
  width: 100%;

  display: flex;
  align-items: center;

  z-index: 10000;
}

.name {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  background: #f6f6fa;
  border-radius: 24px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  cursor: pointer;
  transition: 0.2ms;
  width: 100%;

  min-height: 47px;
}

.opened {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.name > span {
  width: calc(100% - 20px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;
}

.carpet {
  display: flex;
  width: 8px;
  height: 6px;
}

.carpet svg {
  stroke: #1974bb;
  fill: transparent;
}

.carpet_open {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border-top: 0;
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  background: #ffffff;
  padding: 0 30px;
  border-radius: 0 0 24px 24px;
  padding-bottom: 10px;
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  max-height: 200px;
  overflow: auto;
}

.open {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  z-index: 3;
}

.option_container {
  position: relative;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  cursor: pointer;
  transition: all 0.5s;
  padding: 12px 0;
  border-bottom: 1px solid rgba(76, 101, 121, 0.1);
  text-align: left;
}

.option_container:last-of-type {
  border-bottom: 0;
}

.option_container:hover,
.option_container.selected {
  cursor: pointer;
  color: #1974bb;
}

.actions_select {
  width: 100%;
}

.bg_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
}

.active_overlay {
  display: block;
}
