.container {
  display: flex;
  flex-direction: column;
  background-color: #f6f6fa;

  border-radius: 12px;
  margin: -50px -32px;
}

.bronze {
  background: linear-gradient(
    50.58deg,
    #b08d81 5.24%,
    #a8897b 7%,
    #9e8173 19.29%,
    #9e8173 27.2%,
    #c6a090 37.74%,
    #cfa897 42.13%,
    #fcd0b9 55.3%,
    #fcd0b9 68.48%,
    #ba9683 87.8%,
    #b3907e 90.44%,
    #b28e7d 93.07%
  );
}

.silver {
  background: linear-gradient(
    49.3deg,
    #656565 -14.32%,
    #5a5a5a -11.71%,
    rgba(75, 75, 75, 0.36) 6.56%,
    rgba(75, 75, 75, 0.57) 18.3%,
    #838383 33.96%,
    #8f8f8f 40.48%,
    #cecece 60.06%,
    #cecece 79.63%,
    #727272 108.34%,
    #686868 112.25%,
    #676767 116.16%
  );
}

.gold {
  background: linear-gradient(
    41.15deg,
    #a88b6d 3.74%,
    #a08668 5.83%,
    #947e60 20.46%,
    #947e60 29.86%,
    #c0a07d 42.4%,
    #caaa84 47.62%,
    #fcd7a5 63.29%,
    #fcd7a5 78.96%,
    #b3976f 101.94%,
    #ac9069 105.08%,
    #ab8e68 108.21%
  );
}

.tariff_plans {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1036px;
  padding: 50px 32px;
}

.tariff_plans_item_ico {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 150%;
  color: #ffffff;
  margin: 0 auto;
  margin-bottom: 10px;
}

.tariff_plans_item {
  background: #ffffff;
  border-radius: 25px;
  padding: 30px;
  text-align: center;
  margin-right: 30px;
}

.tariff_plans_item:last-of-type {
  margin-right: 0;
}

.tariff_plans_item_name {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #4c6579;
  margin-bottom: 10px;
}

.tariff_plans_item_description {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  color: #4c6579;
  margin-bottom: 19px;
}

.tariff_plans_item_ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 17px;
}

.tariff_plans_item_ul li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.tariff_plans_item_ul li:last-of-type {
  margin-bottom: 0;
}

.tariff_plans_item_ul li:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: rgba(25, 116, 187, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/img/li-check.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  margin-right: 10px;
}

.price-modal .modal_inner {
  width: 1036px;
  background: #f6f6fa;
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  border-radius: 12px;
}

.tariff_plans_item.popular_item {
  background: radial-gradient(
      123.09% 40.07% at -29.26% -2.31%,
      #22a9e0 0%,
      rgba(34, 169, 224, 0) 100%
    ),
    linear-gradient(7.28deg, #43c3d7 -40.25%, rgba(67, 195, 215, 0) 105.8%),
    #1974bb;
  box-shadow: 0px 22px 54px rgba(25, 116, 187, 0.3);
  padding: 50px 30px;
}

.tariff_plans_item.popular_item .tariff_plans_item_name,
.tariff_plans_item.popular_item .tariff_plans_item_description,
.tariff_plans_item.popular_item .tariff_plans_item_ul {
  color: #ffffff;
}

.tariff_plans_item.popular_item .tariff_plans_item_ul li:before {
  background-color: #ffffff;
}

.tariff_plans_item .btn_submit {
  width: 100%;
}

.tariff_plans_item.popular_item .btn_submit {
  background: #ffffff;
  color: #1974bb;
}

.tariff_plans_btns {
  display: flex;
  flex-direction: column;

  row-gap: 10px;
}

.btn_submit {
  padding: 12px 30px;
  background: linear-gradient(
      257.62deg,
      #43c3d7 -30.67%,
      rgba(67, 195, 215, 0) 102.58%
    ),
    #1974bb;
  border-radius: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #ffffff;
  cursor: pointer;
}

.tariff_plans_item.popular_item .btn_paypal {
  color: #ffffff;
}

.btn_submit:hover {
  background: #edfafc;
}

.paypal {
  min-height: 665px;
}

@media screen and (max-width: 1024px) {
  .tariff_plans {
    width: 90%;
  }
}
