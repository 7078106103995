.folders_panel__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;

  position: relative;
}

.folders_panel__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  display: flex;
  align-items: center;
}

.folders_panel__ico {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.folders_panel__ico svg {
  width: 24px;
  height: 24px;
  fill: #4c6579;
}

.select {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.select::after {
  width: 8px;
  height: 6px;
  content: '';
  display: block;
  background-image: url('../../assets/img/downarrow.svg');
  background-size: cover;
  margin-left: 10px;
}

.desc::after {
  transform: rotate(180deg);
}

.folders_panel__body {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 991px) {
  .desctop_breadcrumb {
    display: none;
  }
}