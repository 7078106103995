.avatar {
  margin-right: 10px;
  width: 47px;
  height: 47px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #F6F6FA;
}

.avatar img {
  object-fit: cover;
  width: 100%;
}

.default {
  background-image: url('../../assets/img/avatar-default.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  top: 5px;
  z-index: 1;
}
