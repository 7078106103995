.container {
  /*width: 340px;*/
  padding: 44px 40px 140px 40px;
  background: radial-gradient(
      123.09% 40.07% at -29.26% -2.31%,
      #22a9e0 0%,
      rgba(34, 169, 224, 0) 100%
    ),
    linear-gradient(7.28deg, #43c3d7 -40.25%, rgba(67, 195, 215, 0) 105.8%),
    linear-gradient(0deg, #7034bd, #7034bd);
  border-radius: 12px;
  margin-right: 60px;
  max-width: 340px;
  width: 22%;
  position: relative;
  min-height: calc(100vh - 40px);
}

.sidebar_logo {
  margin-bottom: 52px;
}

.sidebar_widgets {
  padding-left: 9px;
}

.sidebar_widget {
  margin-bottom: 60px;
}

.sidebar_widget__title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
}

.sidebar_widget__ico {
  margin-right: 21px;
  width: 24px;
  height: 24px;
}

.sidebar_widget__ico svg {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

.sidebar_widget__body {
  text-align: left;
}

.sidebar_widget__menu {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-left: 45px;
}

.sidebar_widget__menu li {
  margin-bottom: 20px;
}

.sidebar_widget__menu li a {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #ffffff;
  opacity: 0.6;
  text-decoration: none;
  position: relative;
  display: block;
}

.sidebar_widget__menu li a:hover,
.sidebar_widget__menu li a.current {
  opacity: 1;
}

.sidebar_widget__menu li a:hover:before,
.sidebar_widget__menu li a.current:before {
  content: '';
  width: calc(100% + 79px);
  position: absolute;
  top: -10px;
  left: -30px;
  height: 43px;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 30px 0px 0px 30px;
}

.support {
  position: absolute;
  bottom: 0;
  left: 49px;
  margin-bottom: 47px;
  width: calc(100% - 49px);
}

@media screen and (min-width: 1080px) and (max-width: 1620px) {
  .container {
    margin-right: 20px;
    padding: 44px 20px 140px 20px;
  }
  .sidebar_logo a img {
    width: 100%;
  }
  .support {
    left: 29px;
  }
}

@media screen and (max-width: 1259px) {
  .container {
    display: none;
  }
  .mobile_widgets .sidebar_widget:last-of-type {
    margin-bottom: 0;
    position: absolute;
    bottom: 47px;
  }
  .sidebar_widgets {
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .mobile_widgets .sidebar_widget {
    margin-bottom: 30px;
  }
  .sidebar_widgets.mobile_widgets {
    padding-left: 0;
  }
}
