.container {
  /*padding: 50px 31px;*/
  width: 736px;
}

.video {
  width: 100%;
  height: 100%;

  border-radius: 12px;
}

.images {
  margin-top: 30px;

  display: flex;
  justify-content: space-between;
  /*justify-content: center;*/
  column-gap: 15px;
}

.images div {
  background: rgba(76, 101, 121, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.images div svg {
  width: 24px;
  height: 24px;
  fill: #4c6579;
}

.img {
  width: 124px;

  cursor: pointer;

  border-radius: 12px;
  background: rgba(76, 101, 121, 0.1);
  min-height: 66px;
}

.img_current {
  border: 2px solid #1974bb;
}

.save_button {
  margin: auto;
}

.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .container {
    width: 100%;
  }
  .images {
    margin: 20px 0;
    flex-wrap: wrap;
  }
  .img {
    width: 30%;
    margin-bottom: 10px;
    height: 66px;
  }
}
