.wrap {
  display: flex;
  align-items: center;
}

.about_user {
  font-family: Roboto;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  line-height: normal;
  margin-right: 60px;
  text-align: left;
  white-space: nowrap;
}

.user_name {
  font-weight: bold;
  font-size: 20px;
  color: #1974bb;
}

.user_id {
  font-weight: 500;
  font-size: 15px;
  color: #4c6579;
}

.back_step {
  width: 27px;
  height: 30px;
  border: none;
  background: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 3px;
  outline: none;
}

.back_step svg {
  width: 27px;
  height: 24px;
  fill: #1974bb;
}

@media screen and (min-width: 1259px) and (max-width: 1620px) {
  .about_user {
    width: min-content;
  }
  .user_name {
    font-size: 14px;
  }
  .user_id {
    font-size: 12px;
  }
}
@media screen and (max-width: 1700px) {
  .about_user {
    margin-right: 50px;
  }
}
@media screen and (max-width: 1620px) {
  .about_user {
    margin-right: 10px;
  }
}
@media screen and (min-width: 1259px) and (max-width: 1360px) {
  .about_user {
    margin-right: 5px;
  }
  .back_step {
    width: 17px;
    height: 20px;
  }
  .user_name {
    white-space: normal;
  }
}
@media screen and (max-width: 991px) {
  .user_name {
    font-size: 14px;
  }
}
