.container {
  position: fixed;
  padding: 20px 60px;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  border-radius: 12px;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  width: 400px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #4c6579;
  z-index: 9999999;
}

.container:before {
  content: '';
  width: 22px;
  height: 22px;
  margin-right: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  margin: auto 0;
}

.info:before {
  background: url('../../assets/img/alert/circle-checked.svg') no-repeat;
  background-size: 100%;
}

.warning:before {
  background: url('../../assets/img/alert/alert-circle.svg') no-repeat;
  background-size: 100%;
}

.alert_close {
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  position: absolute;
  opacity: 0.3;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

.alert_close svg {
  fill: #4C6579;
}

.alert_close:hover {
  opacity: 1;
}

@media screen and (max-width: 991px){
  .container {
    width: calc(100% - 30px);
    right: 15px;
  }
}