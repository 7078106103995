.btn_social {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  outline: 0;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.btn_social:last-of-type {
  margin-right: 0;
}

.btn_social.facebook {
  background: #4e6297;
}

.btn_social.facebook:hover {
  background: rgba(78, 98, 151, 0.81);
}

.btn_social.facebook svg {
  width: 17px;
  height: 17px;
}

.btn_social.google {
  background: #c5331e;
}

.btn_social.google:hover {
  background: rgba(197, 51, 30, 0.81);
}

.btn_social.google svg {
  width: 19px;
  height: 19px;
}

.btn_social.twitter {
  background: #55ace3;
}

.btn_social.twitter:hover {
  background: rgba(85, 172, 227, 0.81);
}

.btn_social.twitter svg {
  width: 19px;
  height: 15px;
}

.btn_social svg {
  fill: #ffffff;
}
