.table_controls {
  display: flex;
  justify-content: space-between;
  margin: 62px 25px 0;
  font-size: 15px;
  font-weight: 500;
  color: #4c6579;
}

.table_controls > div {
  display: flex;
  flex-direction: row;
  letter-spacing: 1.2px;
}

.table_controls .page_pagination_left ul:first-of-type {
  margin-right: 29px;
}

.table_controls > div > ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  padding: 15px 0px;
  border-radius: 40px;
  height: 47px;
}

.table_controls > div > ul li {
  padding: 0px 20px;
  position: relative;
}

.page_pagination > li:not(:last-of-type)::after,
.buttons > li:not(:last-of-type)::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  right: 0;
  top: 0;
  background: #4c6579;
  opacity: 0.1;
}

.table_controls .button {
  font-size: 15px;
  font-weight: 500;
  font-family: Roboto;
  color: #4c6579;
  fill: #4c6579;
  border: none;
  background-color: inherit;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table_controls select:hover,
.table_controls select:active,
.table_controls .button:hover,
.table_controls .button:active {
  color: #1974bb;
  fill: #1974bb;
  transition: all 0.3s;
}

.table_controls .button,
.table_controls .button:active,
.table_controls .button:focus {
  outline: none;
}

.table_controls select {
  position: relative;
}

.table_controls select:after,
.table_controls select:before {
  content: '';
  width: 1px;
  height: 5px;
  top: 0;
  left: 0;
  background-color: red;
}

.page_navigation li:nth-child(2) {
  transform: rotate(180deg);
}

.page_navigation li:not(:last-of-type) {
  padding-right: 0px;
}

.selector_block {
  display: flex;
}

.selector {
  position: relative;
}

.options {
  position: absolute;
  top: 20px;
  display: none;
  list-style: none;
  background: white;
  padding: 5px;
  margin: 0;
  border-radius: 10px;
  border: 3px solid #f6f6fa;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.table_controls .options li {
  padding: 5px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}

.table_controls .options li:not(:last-of-type):after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #4c6579;
  opacity: 0.1;
}

.selector > span {
  padding: 0 5px;
  position: relative;
  cursor: pointer;
}

.selector > span svg {
  stroke: #4c6579;
  margin-left: 5px;
  transition: all 0.3s;
}

.options span:hover {
  color: #1974bb;
}

.options span:hover svg {
  stroke: #1974bb;
}

.table_controls .options li:hover {
  background-color: #f6f6fa;
  color: #1974bb;
}

.options_visible {
  display: block;
}

button.see_more {
  display: none;
}

.page_pagination_disabled {
  cursor: default;
  opacity: 0.7;
}

@media screen and (min-width: 1025px) and (max-width: 1439px) {
  .table_controls {
    margin: 40px 25px 0;
  }
}

@media screen and (max-width: 1025px) {
  .table_controls {
    margin: 40px 25px 0;
    justify-content: center;
  }
  .table_controls .page_pagination_right {
    display: none;
  }
  .table_controls .page_pagination_left {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
  }
  .table_controls .page_pagination_left ul {
    width: 100%;
  }
  .table_controls .page_pagination_left ul:first-of-type {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  button.see_more {
    display: block;
    padding: 15px 0px;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    color: #1974bbb3;
    border: 1px solid #1974bb;
    border-radius: 40px;
  }
  .options_visible {
    z-index: 99;
  }
  .table_controls > div > ul li {
    padding: 0px 10px;
  }
}
