.container {
  display: flex;
  /*padding: 20px;*/
  width: 100%;
}

.enter_content {
  width: 100%;
  padding-top: 44px;
  padding-right: 43px;
}

.enter_body.left_sidebar .enter_content {
  width: 100%;
  padding-top: 44px;
  padding-right: 43px;
}

.body_with_right_sidebar {
  display: flex;
}

.body_with_right_sidebar .body_block {
  margin-right: 30px;
  max-width: 950px;
  width: 67%;
}

.graphic_block {
  background: #f6f6fa;
  border-radius: 12px;
  padding: 20px 33px 35px 33px;
  margin-bottom: 30px;
}

.graphic_block_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 29px;
}

.graphic_block_title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  display: flex;
  align-items: center;
}

.graphic_block_ico {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 21px;
}

.graphic_block_ico svg {
  width: 22px;
  height: 22px;
  fill: #4c6579;
}

.graphic_block_body {
  height: 470px;
}

.right_block {
  width: 32%;
  max-width: 460px;
}

.right_block .widget_block {
  margin-bottom: 30px;
}

.right_block .widget_block:last-of-type {
  margin-bottom: 0;
}

.widgets_block_row {
  display: flex;
  justify-content: space-between;
}

.widgets_block_row .widget_block {
  width: 32%;
  margin-right: 30px;
}

.widgets_block_row .widget_block:last-of-type {
  margin-right: 0;
}

.widget_block {
  background: #f6f6fa;
  border-radius: 12px;
  padding: 22px 30px 30px 29px;
}

.widget_block.right_widget {
  margin-bottom: 30px;
}

.widget_block.right_widget:last-of-type {
  margin-bottom: 0;
}

.widget_block_title {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  color: #4c6579;
  margin-bottom: 8px;
}

.widget_block_body {
  height: 237px;
}

@media screen and (max-width: 991px) {
  .container {
    display: block;
  }
  .widget_block.right_widget:last-of-type {
    margin-left: 0;
  }
  .widgets_block_row {
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .widgets_block_row .widget_block {
    margin-right: 0;
    width: 100%;
  }
  .graphic_block,
  .widget_block.right_widget {
    margin-right: -15px;
    margin-left: -15px;
  }
  .widget_block,
  .graphic_block,
  .widget_block.right_widget {
    border-radius: 0;
    margin-bottom: 10px;
    padding: 22px 16px;
  }
}
