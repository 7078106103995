.close {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
  width: 14px;
  height: 14px;
  opacity: 0.3;
}

.close:before, .close:after {
  position: absolute;
  left: 7px;
  content: ' ';
  height: 14px;
  width: 2px;
  background-color: #4C6579;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.form_item input {
  margin-bottom: 10px;
  text-align: center;
}

.form_item input::-webkit-input-placeholder {
  opacity: 1;
}

.form_item input::-moz-placeholder {
  opacity: 1;
}

.form_item input:-moz-placeholder {
  opacity: 1;
}

.form_item input:-ms-input-placeholder {
  opacity: 1;
}

.submit_btn {
  width: 100%;
}

.modal_form_title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  margin-bottom: 22px;
  text-align: center;
}

.modal_form_subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #4c6579;
  margin-bottom: 25px;
}
