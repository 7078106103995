.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

.table > thead > tr > th {
  padding-left: 20px;
}

.table > thead > tr > th,
.table > tbody > tr > td {
  position: relative;
}

.table > thead > tr > th {
  padding: 22px 0;
  white-space: nowrap;
}
.table > tbody > tr > td {
  padding: 19px 0;
}

.table > thead > tr > th:after,
.table > tbody > tr > td:after {
  position: absolute;
  content: '';
  background-color: #4c6579;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  opacity: 0.1;
}

.table > thead > tr > th:first-of-type,
.table > tbody > tr > td:first-of-type {
  padding-left: 30px;
  padding-right: 20px;
  width: 27px;
}

.table > thead > tr > th:first-of-type:after,
.table > tbody > tr > td:first-of-type:after {
  right: 0;
  left: auto;
  width: calc(100% - 30px);
}

.table > thead > tr > th:last-of-type,
.table > tbody > tr > td:last-of-type {
  text-align: right;
  margin-right: 20px;
  padding-right: 30px;
}

.table > thead > tr > th:last-of-type:after,
.table > tbody > tr > td:last-of-type:after {
  width: calc(100% - 30px);
}

.table > thead > tr,
.table > tbody > tr {
  position: relative;
}

@media screen and (min-width: 1025px) and (max-width: 1520px) {
  .table {
    font-size: 11px;
  }
  .table > tbody > tr > td {
    vertical-align: middle;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1439px) {
  .table > tbody > tr > td {
    vertical-align: top;
  }
}
