.container {
  /*padding: 40px 50px;*/
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  margin-bottom: 22px;
  text-align: center;
}

.item {
  margin-bottom: 20px;
}

.item:last-of-type {
  margin-bottom: 0;
}

.button {
  margin: auto;
}
