.modal_form_title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  margin-bottom: 11px;
}

.form_item input {
  margin-bottom: 10px;
  text-align: center;
}

.form_item input::-webkit-input-placeholder {
  opacity: 1;
}

.form_item input::-moz-placeholder {
  opacity: 1;
}

.form_item input:-moz-placeholder {
  opacity: 1;
}

.form_item input:-ms-input-placeholder {
  opacity: 1;
}

.submit_btn {
  width: 100%;
}
