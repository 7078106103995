.container {
  width: 260px;
}

.video_info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.video_name {
  width: 195px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video_name,
.video_size {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.video_size {
  opacity: 0.4;
}

.progress {
  width: 100%;
  height: 3px;
  position: relative;
}

.progress div {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.progress_full {
  width: 100%;
  background: #4c6579;
  opacity: 0.2;
}

.progress_done {
  background: linear-gradient(
      257.62deg,
      #43c3d7 -30.67%,
      rgba(67, 195, 215, 0) 102.58%
    ),
    #1974bb;
}
