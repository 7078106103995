.container {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.name {
  margin-right: 10px;
  white-space: nowrap;
}

.input {
  padding: 12px 26px;
  width: 100%;
  border: none;
  outline: none;
  background: #f6f6fa;
  border-radius: 40px;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #4c6579;
  /* opacity: 0.6; */
}

.input::-webkit-input-placeholder {
  color: #4c6579;
}
.input::-moz-placeholder {
  color: #4c6579;
}
.input:-moz-placeholder {
  color: #4c6579;
}
.input:-ms-input-placeholder {
  color: #4c6579;
  /* opacity: 0.6; */
}

.input_icon {
  position: absolute;
  left: 26px;
  top: 14px;

  width: 16px;
  height: 16px;
  fill: #4c6579;
}

.input.validate:focus {
  border: 1px solid red;
}

.input.validate_without_focus {
  border: 1px solid red;
}

.input + .validate_text {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  padding: 0 20px;
  border-radius: 4px;
  width: 100px;
  color: white;
  background-color: #4c6579;
  z-index: 5;
}

.validate_text:before {
  content: '';
  position: absolute;
  border: 10px solid transparent;
}

.input:focus + .validate_text {
  display: flex;
}

.validate_text.validate_right {
  height: calc(100% - 10px);
  top: 0;
  left: calc(100% + 10px);
  width: fit-content;
}

.validate_text.validate_right:before {
  border-right-color: #4c6579;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
}

.validate_text.validate_top {
  top: -10px;
  transform: translate(-50%, -100%);
  width: max-content;
  left: 50%;
}

.validate_text.validate_top:before {
  border-top-color: #4c6579;
  transform: translateX(-50%);
  bottom: -20px;
  left: 50%;
}

.validate_text.validate_bottom {
  bottom: -10px;
  transform: translate(-50%, 100%);
  width: max-content;
  left: 50%;
}

.validate_text.validate_bottom:before {
  border-bottom-color: #4c6579;
  transform: translateX(-50%);
  top: -20px;
  left: 50%;
}

.validate_text.validate_left {
  height: calc(100% - 10px);
  top: 0;
  right: calc(100% + 10px);
  width: max-content;
}

.validate_text.validate_left:before {
  border-left-color: #4c6579;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

@media screen and (max-width: 991px) {
  .input_icon {
    top: 10px;
  }
}
