.form_item input {
  margin-bottom: 10px;
  text-align: center;
}

.form_item input::-webkit-input-placeholder {
  opacity: 1;
}

.form_item input::-moz-placeholder {
  opacity: 1;
}

.form_item input:-moz-placeholder {
  opacity: 1;
}

.form_item input:-ms-input-placeholder {
  opacity: 1;
}

.submit_btn {
  width: 100%;
}
