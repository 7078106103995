.container {
  margin-top: 30px;
  padding-bottom: 21px;

  background: #f6f6fa;
  border-radius: 12px;

  padding: 22px 30px 30px 30px;

  text-align: left;

  position: relative;

  height: 100%;
}

.body {
  display: flex;
  flex-wrap: wrap;
}

.body_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31%;
  border-radius: 12px;
  margin-right: 9px;
  margin-bottom: 9px;
  max-width: 127px;
}

.body_item:nth-child(3n) {
  margin-right: 0;
}

.body_item img {
  width: 100%;
  border-radius: 12px;
}

.body_item:hover {
  position: relative;
}

.body_item:hover:before {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: linear-gradient(
      257.62deg,
      #43c3d7 -30.67%,
      rgba(67, 195, 215, 0) 102.58%
    ),
    #1974bb;
  opacity: 0.7;
}

.widget_gif_panel {
  position: absolute;
  display: none;
}

.body_item:hover .widget_gif_panel {
  display: flex;
  background: #ffffff;
  padding: 7px;
  border-radius: 40px;
  align-items: center;
  justify-content: space-between;
}

.panel_size {
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
}

.widget_gif_panel_btn {
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 6px;
  padding: 0;
}

.widget_gif_panel_btn svg {
  width: 12px;
  height: 12px;
  fill: #4c6579;
}

.widget_gif_panel_btn:hover svg {
  fill: #1974bb;
}

.remove_gif {
  width: 11px;
  height: 11px;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.remove_gif svg {
  width: 11px;
  height: 11px;
  fill: #ffffff;
}

.body_item:hover .remove_gif {
  display: flex;
}

.prev_page,
.next_page {
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
}

.prev_page svg,
.next_page svg {
  width: 20px;
  height: 20px;
  fill: #4c6579;
}

.prev_page svg {
  transform: rotate(-180deg);
}

.prev_page {
  margin-right: 19px;
}

.prev_page:hover svg,
.next_page:hover svg {
  fill: #1974bb;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

@media screen and (min-width: 1080px) and (max-width: 1620px) {
  .container {
    padding: 22px 20px 30px 20px;
  }

  .body_item {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1259px) {
  .container {
    grid-area: widget3;
    margin-left: 15px;
    height: auto;
  }
}

@media screen and (max-width: 991px) {
  .container {
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
    margin-bottom: 10px;

    padding: 30px 15px;
    margin-top: 0;

    order:3;
  }

  .body_item {
    width: 31%;
  }
}
