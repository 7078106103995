.folders_panel__body {
  display: flex;
  flex-wrap: wrap;

  position: relative;
}

.folder_content {
  background: #f6f6fa;
  border-radius: 12px;
  padding-bottom: 30px;
}

.folder_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 21px;
  padding-bottom: 18px;
  transition: 0.2ms;

  padding-left: 27px;
  padding-right: 31px;
}

.folder_content_header.header_active {
  background: linear-gradient(
      257.62deg,
      #43c3d7 -30.67%,
      rgba(67, 195, 215, 0) 102.58%
    ),
    #1974bb;
  border-radius: 12px 12px 0px 0px;
}

.folder_content_header_left {
  display: flex;
  align-items: center;
}

.folder_content_header_title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-right: 20px;
}

.header_active .folder_content_header_title,
.header_active .folder_content_header_subtitle {
  color: #ffffff;
}

.folder_content_header_subtitle {
  opacity: 0.3;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
  top: 3px;
  margin-right: 176px;
}

.folder_header_action {
  display: none;
}

.header_active .folder_header_action {
  display: block;
}

.action_video {
  position: relative;
  padding-top: 21px;
  padding-bottom: 18px;
  margin-top: -21px;
  margin-bottom: -18px;
}

.action_video:hover .ul_action_video {
  display: block;
  position: absolute;
  top: 51px;
  width: 136px;
  text-align: left;
}

.folder_action_others .action_video:hover .ul_action_video {
  width: 137px;
}

.action_video_title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.folder_action_add_to .action_video_title {
  padding-right: 76px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.folder_action_others .action_video {
  margin-left: 76px;
}

.action_video_title .arrow {
  margin-left: 10px;
  display: flex;
  width: 8px;
  height: 6px;
}

.action_video_title .arrow svg {
  stroke: #ffffff;
  fill: none;
}

.ul_action_video {
  display: none;
  transition: 0.2ms;
  list-style: none;
  padding: 20px 30px;
  margin: 0;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  border-radius: 12px;
  z-index: 99;
}

.ul_action_video li {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #4c6579;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid rgba(76, 101, 121, 0.1);
}

.ul_action_video li:first-of-type {
  padding-top: 0;
}

.ul_action_video li:last-of-type {
  padding-bottom: 0;
  border-bottom: none;
}

.ul_action_video li:hover {
  color: #1974bb;
}

.sort_video {
  position: relative;
  display: flex;
  align-items: center;

  cursor: pointer;
}

.sort_video:after {
  width: 8px;
  height: 6px;
  content: '';
  display: block;
  background-image: url(../../assets/img/downarrow.svg);
  background-size: cover;
  margin-left: 10px;
}

.sort_video.desc::after {
  transform: rotate(180deg);
}

.header_active .sort_video {
  display: none;
}

.videos {
  padding-left: 27px;
  overflow: hidden;
  padding-right: 31px;
  position: relative;
}

.videos:before {
  content: '';
  position: absolute;
  left: 27px;
  width: calc(100% - 58px);
  background: rgba(76, 101, 121, 0.1);
  height: 1px;
  top: 0;
}

.pagination {
  padding: 27px 27px 0 31px;
}

@media screen and (max-width: 991px) {
  .videos {
    padding-left: 15px;
    padding-right: 15px;
  }
  .folder_content {
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
  }
  .folder_content_header {
    padding-right: 15px;
    padding-left: 15px;
  }
  .folder_content_header_subtitle {
    margin-right: 48px;
    flex-shrink: 0;
    top: 0;
  }
  .folder_content_header.header_active {
    border-radius: 0;
  }
  .folder_action_add_to .action_video_title {
    padding-right: 37px;
    border-right: 0;
  }
  .folder_action_others .action_video {
    margin-left: 0;
  }
  .action_video_title span {
    display: none;
  }
  .action_video:hover .ul_action_video {
    right: 0;
  }
  .folder_content_header_title {
    font-size: 16px;
    line-height: 34px;
    margin-right: 10px;
  }
}
