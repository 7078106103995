.container {
  /*padding: 40px 50px;*/
  padding: 0 8px;

  width: 100%;

  text-align: center;
}

.input {}

.btn {
  margin-top: 20px;
  display: inline-block;
}
