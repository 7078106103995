.container {
 /* padding: 40px 50px;*/
  padding: 0 8px;

  width: 321px;
}

.text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  margin-bottom: 22px;
  text-align: center;
}

.buttons {
  /*margin-bottom: 20px;*/
  display: flex;

  justify-content: space-between;

  gap: 10px;
}
