.container {
  color: #1974bb;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #f6f6fa;
  border-radius: 40px;
  margin-left: 20px;
}

.icon {
  width: 16px;
  height: 16px;
  fill: #1974bb;
}

.info .count {
  padding: 0 6px 0 8px;
}

@media screen and (max-width: 1024px) {
  .container {
    background: inherit;
  }
  .info .text {
    display: none;
  }
}
