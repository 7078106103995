.container {
  padding: 50px 31px;
}

.edit_area {
  position: relative;
  margin-bottom: 15px;
}

.video {
  max-width: 100%;
  max-height: 100%;

  border-radius: 12px;
}

.img_wrapper {
  position: absolute;
}

.logo {
  border: 2px solid #274aff;
}

.settings {
  display: flex;

  column-gap: 22px;
}

.save_button {
  width: 180px;
  margin: 28px auto 0 auto;
}

.point {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #274aff;
  background-color: #ffffff;
}
