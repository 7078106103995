.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 500;
}
.section {
  width: 30%;
  background: #f6f6fa;
  border-radius: 12px;
  padding: 20px 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.section:nth-child(2) {
  margin: 0 30px 0px 30px;
}
.section:nth-child(4) {
  margin: 30px 0;
  width: 100%;
  padding-top: 31px;
}
.section_header {
  font-weight: 500;
  font-size: 15px;
  color: #4c6579;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.section_header a {
  color: #27ae60;
  font-size: 10px;
  letter-spacing: 0.3px;
  text-decoration: none;
  align-items: center;
}
.section_items {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.section_item {
  position: relative;
  padding: 10px 0 5px;
  text-align: left;
}
.section:nth-child(4) .section_header {
  margin-bottom: 11px;
}
.section:nth-child(4) .section_item {
  position: relative;
  padding: 0 0 6px;
  text-align: left;
  line-height: 15px;
}
.section:not(:last-of-type) .section_item:after {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #4c6579;
  content: '';
  width: 100%;
  height: 1px;
  opacity: 0.1;
}

.item_title {
  font-size: 12px;
  color: #4c6579;
  width: 160px;
  display: inline-block;
}
.item_value {
  font-size: 12px;
  color: #4c6579;
  opacity: 0.7;
}
.section_columns {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, 1fr);
  width: 100%;
  position: relative;
  font-size: 12px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}
.button_container input {
  background: #ffffff;
  border-radius: 40px;
  padding: 12px 20px;
  font-size: 10px;
  line-height: 15px;
  margin-left: 50px;
}
.button button {
  font-size: 12px;
  line-height: 18px;
}

.section_item.action {
  cursor: pointer;
}

.section_item.email {
  display: flex;
}
.section_item.email .confirmed {
  display: inline-flex;
  align-items: center;
  color: #27ae60;
}
.confirmed_text {
  padding-left: 5px;
}

.table {
  font-size: 12px;
  border-collapse: collapse;
}
.table_row {
  width: 100%;
}

.table_title,
.table_value {
  text-align: left;
  position: relative;
  padding: 10px 0 4px;
}
.table_title {
  min-width: 140px;
  width: 30%;
}
.table_value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  color: #4c6579;
  opacity: 0.7;
}
.table_title:after,
.table_value:after {
  position: absolute;
  content: '';
  height: 1px;
  background-color: #4c65791a;
  width: 100%;
  top: 0;
}

.table_title:after {
  left: 0;
}
.table_value:after {
  right: 0;
}
.confirmed {
  display: inline-flex;
  align-items: center;
  color: #27ae60;
  margin-left: 10px;
}
.confirmed_text {
  padding-left: 5px;
}
.long_text {
  white-space: nowrap;
  overflow: hidden;
  padding: 0 15px 0 0;
  text-overflow: ellipsis;
  position: relative;
  height: 3em;
}
.long_text a {
  position: absolute;
}
.size::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 204, 0, 0.2), #fc0 100%);
}
.long_text a,
.link a {
  color: inherit;
  text-decoration: none;
}
@media screen and (max-width: 1259px) {
  .table_title {
    min-width: 110px;
    width: 30%;
  }
  .table_value {
    line-height: 1.5em;
  }
  .section_columns {
    grid-template-rows: repeat(6, 1fr);
  }
}

@media screen and (max-width: 991px) {
  .container {
    width: 100vw;
    margin-left: -15px;
    margin-right: -15px;
  }
  .section:nth-child(2) {
    margin: initial;
  }

  .container .section {
    width: 100%;
    border-radius: 0;
    margin: 0 0 10px 0;
  }
}

@media screen and (max-width: 660px) {
  .section_columns {
    grid-template-rows: auto;
    grid-auto-flow: initial;
    grid-template-columns: repeat(3, 1fr);
  }
  .button_container {
    flex-wrap: wrap;
  }
  .button_container > div {
    width: 100%;
  }
  .button {
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 490px) {
  .section_columns {
    grid-template-columns: repeat(2, 1fr);
  }
}
