.login_social {
  display: flex;
  justify-content: center;
  margin-bottom: 11px;
}

.text_seporrator {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 14px;
}

.payment_methods {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;
}

.payment_methods label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment_methods label img {
  margin-right: 13px;
  display: block;
}

.payment_methods label img:last-of-type {
  margin-right: 0;
}

.text_login {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #4c6579;
  margin-top: 20px;
}

.text_login span {
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  color: #1974bb;
}

.text_login a:hover {
  text-decoration: underline;
}

.information_form {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #4c6579;
  padding: 0 20px;
  margin-top: 20px;
}

.information_form a {
  line-height: 18px;
  color: #1974bb;
  text-decoration: none;
}
