.mobile_row {
  display: none;
}

.mobile_icon,
.mobile_icon svg {
  width: 16px;
  height: 16px;
}

.mobile_icon {
  margin-right: 10px;
}

.mobile_icon svg {
  fill: #4c6579;
}

.mobile_submenu {
  position: relative;
  width: 100%;
}

.mobile_submenu:first-of-type {
  margin-right: 11px;
}

.mobile_title {
  padding: 12px 20px;
  background: #f6f6fa;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_title::after {
  width: 8px;
  height: 6px;
  content: '';
  display: block;
  background-image: url('../../assets/img/downarrow.svg');
  background-size: cover;
  margin-left: 20px;
}

.mobile_submenu ul {
  list-style: none;
  margin: 0;
  padding: 30px 20px;
  display: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  border-radius: 20px;
  z-index: 4;
  width: 100%;
}

.mobile_submenu:hover ul {
  display: block;
}

.mobile_submenu ul li {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(76, 101, 121, 0.1);
}

.mobile_submenu ul li:first-of-type {
  padding-top: 0;
}

.mobile_submenu ul li:last-of-type {
  padding-bottom: 0;
  border-bottom: none;
}

.mobile_submenu ul li:hover {
  color: #1974bb;
}

.mobile_submenu ul li:hover svg {
  fill: #1974bb;
}

@media screen and (max-width: 991px) {
  .mobile_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 29px;
  }
}
