.container {
  position: relative;
}

.actions {
  display: flex;

  justify-content: space-between;

  font-size: 14px;
  line-height: 15px;
}

.actions > div > div > div {
  background: #ffffff;
  width: 119px;
  font-size: 14px;
  line-height: 15px;
  padding: 9px 20px;
}

.actions > div > div > div:first-of-type > span {
  opacity: 0.6;
}

.actions > div > div > div:last-of-type > span {
  padding: 9px 0;
  font-size: 14px;
  line-height: 15px;
}

/*.actions_select {
  width: 50%;
} */

.info {
  margin: 25px 0;

  font-size: 10px;
  line-height: 10px;
  /* identical to box height, or 100% */

  letter-spacing: -0.05px;

  /* с4 */

  color: #4c6579;
  opacity: 0.5;
}

.checkbox_settings {
  display: flex;

  /*row-gap: 18px;*/

  flex-wrap: wrap;
}

.checkbox_settings__checkbox {
  width: 50%;
  margin-bottom: 18px;
}

.checkbox_settings__checkbox span:last-of-type {
  font-size: 14px;
  line-height: 15px;
}

.upload_logo_btn {
  max-width: 166px;
  font-size: 14px;
  line-height: 15px;
  color: #1974bb;
  align-items: center;
  display: flex;
}

.upload_logo_btn svg {
  fill: #1974bb;

  width: 12px;
  height: 12px;

  margin-right: 10px;
}

.button_settings {
  width: 166px;
}

.button_settings > div {
  /* max-width: 166px !important; */
}

.button_settings > div button {
  border: 1px solid rgba(25, 116, 187, 0.2);
  border-radius: 17px;
  font-size: 10px;
  line-height: 15px;
}

@media screen and (min-width: 1080px) and (max-width: 1620px) {
  .actions_select > div:first-of-type {
    max-width: 30px;
  }

  .actions > div > div > div {
    width: 100px;
  }

  .checkbox_settings__checkbox {
    width: 48%;
  }

  .checkbox_settings {
    justify-content: space-between;
  }
}

@media screen and (max-width: 991px) {
  .actions > div > div:first-of-type {
    max-width: 30px;
  }
}
