.enter_body.left_sidebar .enter_content {
  width: 100%;
  padding-top: 44px;
  padding-right: 43px;
}

.profile_edit,
.promo_edit {
  margin-bottom: 30px;
}

.main_widget {
  /*min-width: 947px;*/
  background: #f6f6fa;
  border-radius: 12px;
  padding: 20px 30px;

  position: relative;
}

.promo_edit_wrapper .form_item {
  margin-right: 34px;
  width: 100%;
}

.promo_edit_wrapper .submit_btn {
  min-width: 179px;
}

.promo_edit_wrapper {
  display: flex;
  padding-top: 30px;
  padding-bottom: 10px;
}

.main_widget .form_item input {
  background: #ffffff;
  padding-right: 50px;
}

.main_widget .form_item input:disabled {
  opacity: 0.6;
}

.main_widget .profile_edit_block .submit_btn {
  width: 210px;
}

.main_widget_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(76, 101, 121, 0.1);
}

.main_widget_title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  display: flex;
  align-items: center;
}

.main_widget_ico {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 21px;
  flex-shrink: 0;
}

.profile_edit_ico svg {
  width: 22px;
  height: 22px;
}

.main_widget svg {
  fill: #4c6579;
}

.profile_edit_action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon_plans {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  color: #ffffff;
  border-radius: 100%;
  margin-left: 10px;

  text-transform: uppercase;
}

.profile_edit_block {
  padding: 30px 0;
  border-bottom: 1px solid rgba(76, 101, 121, 0.1);
}

.profile_edit_block:last-of-type {
  padding-bottom: 0;
  border-bottom: 0;
}

.widget_block {
  background: #f6f6fa;
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 30px;
}

.widget_block:last-of-type {
  margin-bottom: 0;
}

.widget_block.player_logo {
  min-height: 544px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.widget_block_header {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(76, 101, 121, 0.1);
  width: 100%;
}

.widget_block_ico {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 21px;
}

.widget_block.player_logo .widget_block_ico {
  width: 22px;
  height: 18px;
}

.widget_block.color_scheme .widget_block_ico svg {
  width: 16px;
  height: 22px;
}

.widget_block_ico svg {
  fill: #4e6297;
}

.widget_block.player_logo .widget_block_ico svg {
  width: 22px;
  height: 18px;
}

.widget_block_panel {
  width: 100%;
}

.player_logo_panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player_logo_panel span {
  font-size: 12px;
  line-height: 18px;
}

.player_logo-panel_action {
  display: flex;
  transition: 0.2ms;
  background: #ffffff;
  border-radius: 40px;
  padding: 12px 24px;
  align-items: center;
}

.player_logo_panel_action__item {
  display: flex;
  margin-right: 15px;
}

.player_logo_panel_action__item:last-of-type {
  margin-right: 0;
}

.player_logo_btn {
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  border: none;
  background: none;
  border-radius: 0;
  justify-content: center;
  align-items: center;
}

.player_logo_ico {
  width: 16px;
  height: 16px;
  display: flex;
}

.player_logo_btn svg {
  fill: #4c6579;
  width: 16px;
  height: 16px;
}

.color_change_group {
  padding-top: 16px;
}

.color_change_item {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 20px;
  outline: 0;
  cursor: pointer;
}

.color_change_item input[type='color'] {
  border: none;
  border-radius: 0;
  border-radius: 8px;
  border: 2px solid #ffffff;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  outline: 0;
  cursor: pointer;
}

.color_change_item input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color_change_item input[type='color']::-webkit-color-swatch {
  border: none;
}

.wrapper_input {
  width: 100%;
  max-width: 430px;
  margin-right: 43px;
}

.profile_edit_block_item {
  display: flex;
  margin-bottom: 20px;
}

.profile_edit_block_item:last-of-type {
  margin-bottom: 0;
}

.wrapper_input.show_info_input {
  position: relative;
}

.info_icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 21px;
  top: 12px;
  cursor: help;
  z-index: 2;
}

.info_icon svg {
  width: 22px;
  height: 23px;
  fill: #4c6579;
  opacity: 0.3;
}

.info_message {
  position: absolute;
  padding: 8px 20px;
  background: #4c6579;
  color: #ffffff;
  border-radius: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 209px;
  left: -400%;
  top: -50px;
}

.change_avatar {
  padding: 6px 8px 6px 30px;
  background: #ffffff;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.change_avatar .form_item {
  width: 72px;
}

.payment_methods {
  padding: 28px 0;
  justify-content: end;
}

.payment_methods input {
  width: 26px;
  height: 26px;
  background: #ffffff;
}

.payment_methods span {
  left: 2px;
}

.payment_methods label {
  display: flex;
  align-items: center;
}

.img_logo_player {
  width: 185px;
}

.player_logo_panel_action {
  display: flex;
  transition: 0.2ms;
  background: #ffffff;
  border-radius: 40px;
  padding: 12px 24px;
  align-items: center;
}

.change_btn {
  padding: 12px 30px;
  background: linear-gradient(
      257.62deg,
      #43c3d7 -30.67%,
      rgba(67, 195, 215, 0) 102.58%
    ),
    #1974bb;
  border-radius: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #ffffff;
  cursor: pointer;
}

.change_btn:hover {
  background: #43c3d7;
}

@media screen and (min-width: 992px) and (max-width: 1259px) {
  .video_settings {
    display: flex;
  }
  .video_settings .widget_block {
    width: 100%;
    margin-bottom: 0;
  }

  .widget_block.player_logo {
    min-height: auto;
  }

  .wrapper_input {
    max-width: none;
  }

  .video_settings .widget_block:first-of-type {
    margin-right: 30px;
  }
}

@media screen and (max-width: 991px) {
  .main_widget,
  .widget_block {
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
  }
  .main_widget,
  .widget_block {
    padding-left: 15px;
    padding-right: 15px;
  }
  .profile_edit,
  .promo_edit,
  .payment_edit,
  .widget_block.player_logo {
    margin-bottom: 10px;
  }

  .widget_block.player_logo {
    min-height: 289px;
  }
  .profile_edit_action > div {
    width: 57%;
  }
  .profile_edit_action button {
    font-size: 12px;
    line-height: 14px;
  }
  .profile_edit_action > div.icon_plans {
    width: 40px;
  }
  .main_widget .profile_edit_block .change_avatar .submit_btn {
    width: 24%;
    font-size: 12px;
    line-height: 14px;
  }
  .change_avatar button {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .profile_edit_block_item,
  .promo_edit_wrapper {
    flex-wrap: wrap;
  }
  .wrapper_input {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .main_widget .profile_edit_block .submit_btn,
  .promo_edit_wrapper .submit_btn {
    width: 100%;
  }
  .promo_edit_wrapper .form_item {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .payment_methods {
    justify-content: space-between;
  }
}
