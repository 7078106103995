.container {
  display: flex;

  align-items: center;
}

.name {
  margin-right: 10px;
}

.input {
  padding: 12px 26px;
  width: 122px;
  border: none;
  outline: none;
  background: #f6f6fa;
  border-radius: 40px;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: rgba(76, 101, 121, 0.6);
  text-align: center;
}

.input::-webkit-input-placeholder {
  color: #4c6579;
  opacity: 0.6;
}
.input::-moz-placeholder {
  color: #4c6579;
  opacity: 0.6;
}
.input:-moz-placeholder {
  color: #4c6579;
  opacity: 0.6;
}
.input:-ms-input-placeholder {
  color: #4c6579;
  opacity: 0.6;
}
