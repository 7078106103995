.container {
  height: 306px;

  width: 320px;
}

.info {
  display: flex;

  height: 228px;

  justify-content: center;
  align-items: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  /* or 30px */

  /* с4 */

  color: #4c6579;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.btns > div {
  width: 155px;
}
