.container {
  position: relative;
}

.video_block_wrapper {
}

.video {
  background-color: black;
  width: 100%;
  height: 100%;

  border-radius: 12px 12px 0 0;
}

.video_controls {
  height: 72px;
  width: 100%;
  background: #4c6579;
  border-radius: 0 0 12px 12px;
  padding: 8px 20px 16px 18px;
}

.video_player_timeline {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.video_player_bottom {
  display: flex;
  align-items: center;
}

.video_player_play {
  width: 13px;
  height: 16px;
  display: flex;
  margin-right: 15px;
  cursor: pointer;
}

.video_player_play svg {
  /* fill: #ffffff; */
}

.video_player_time {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;

  margin-left: 15px;
}

.timer {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
}

.interval_wrapper {
  margin-right: 20px;
  margin-left: 20px;

  width: 100%;

  align-items: center;
  display: flex;

  position: relative;
}

.end_timer {
}
