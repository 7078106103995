.container {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(246, 246, 250, 0.2);
}
