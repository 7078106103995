.container{
  /* max-height: 50vh; */
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.container img {
  max-height: 50vh;
}

.hidden_canvas{
  position: absolute;
  pointer-events: none;
}

.button_save{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px;
  background: linear-gradient(257.62deg, #43C3D7 -30.67%, rgba(67, 195, 215, 0) 102.58%), #1974BB;
  border-radius: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #FFFFFF;
  border: none;
  margin-top: 30px;
}
