.container {
  display: flex;
  text-align: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  transition: opacity 0.15s linear;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
}

.miniature {
  top: initial;
  left: initial;
  right: 50px;
  bottom: 50px;

  max-width: 440px;
  max-height: 260px;

  border-radius: 12px;
}

.wrapper {
  transition: -webkit-transform 0.3s ease-out;
  min-width: 400px;
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  border-radius: 12px;
  background: #ffffff;

  transform: translate(0, 0);
  position: relative;
  z-index: 999;

  margin: auto;

  /*max-width: 800px;*/
}

.fullscreen {
  width: 100%;
  height: 100%;

  max-width: none;
}

.content {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  padding: 50px 32px;
}

.without_frame {
  padding: 0;
}

.title {
  /*padding-top: 50px;*/
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 23px;
}

.close {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.3;
  top: 14px;
  right: 15px;
  cursor: pointer;
  padding: 0;

  z-index: 999999;
}

.close:hover {
  opacity: 1;
}

.close svg {
  fill: #4c6579;
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 991px) {
  .container,
  .content {
    display: block;
  }

  .wrapper {
    min-width: auto;
  }

  .content {
    padding: 50px 15px;
  }

}
