.container {
  width: 190px;
}

.button {
  padding: 12px 30px;
  width: 100%;
  background: linear-gradient(257.62deg,
      #43c3d7 -30.67%,
      rgba(67, 195, 215, 0) 102.58%),
    #1974bb;
  border-radius: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #ffffff;
  cursor: pointer;
  font-family: Roboto;

  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
}

.button:hover {
  background: #43c3d7;
}

.button:active {
  background: #22a9e0;
}

.button:disabled,
.button:disabled:hover,
.button:disabled:active {
  background: #4C6579;
  opacity: 0.3;
}

.no_bg {
  background: none;
  border: 1px solid #1974bb;
  color: #1974bb;
}

.no_bg:hover {
  background: none;

  color: #43c3d7;
  border-color: #43c3d7;
}

.no_bg:active {
  background: none;

  border-color: #22a9e0;
  color: #22a9e0;
}
