.body_full_width {
  text-align: left;
  padding: 11px 44px 11px 31px;
  background: #F6F6FA;
  border-radius: 12px;
}

.body_full_width p {
  margin: 20px 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}

.body_full_width h2 {
  margin: 20px 0;
  color: #1974BB;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.body_full_width ol,
.body_full_width ul {
  padding: 0;
  padding-left: 16px;
  margin: 20px 0;
}

.body_full_width ul {
  list-style: none;
}

.body_full_width ul li {
  position: relative;
}

.body_full_width ol,
.body_full_width ul {
  padding: 0;
  padding-left: 16px;
  margin: 20px 0;
}

.body_full_width ol li,
.body_full_width ul li {
  margin-bottom: 10px;
}

.body_full_width ol li:last-of-type,
.body_full_width ul li:last-of-type {
  margin-bottom: 0;
}

.body_full_width ul li:after {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  content: '';
  position: absolute;
  top: 6px;
  left: -16px;
  background: linear-gradient(0deg, #1974BB, #1974BB), linear-gradient(257.62deg, #43C3D7 -30.67%, rgba(67, 195, 215, 0) 102.58%);
}

.body_full_width ul li:before {
  width: 6px;
  height: 6px;
  background: #f6f6fa;
  position: absolute;
  top: 8px;
  left: -14px;
  border-radius: 100%;
  content: '';
  z-index: 2;
}

.body_full_width ol li::marker {
  color: #1974BB;
  font-weight: bold;
}

.enter_content {
  padding-top: 44px;
  width: 100%;
  padding-bottom: 40px;
  padding-right: 43px;
}

@media screen and (min-width: 1080px) and (max-width: 1620px) {
  .enter_content {
    padding-right: 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1259px) {
  .enter_content {
    padding-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .enter_content {
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}