.container {
  position: relative;
  background: radial-gradient(123.09% 40.07% at -29.26% -2.31%,
      #22a9e0 0%,
      rgba(34, 169, 224, 0) 100%),
    linear-gradient(7.28deg, #43c3d7 -40.25%, rgba(67, 195, 215, 0) 105.8%),
    #1974bb;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container:after {
  background-image: url(../../assets/img/bg-play.svg);
  width: 1120px;
  height: 732px;
  position: absolute;
  content: '';
  /*top: 348px;
  left: 800px;*/
  top: 18%;
  left: 42%;
  opacity: 0.11;
}

.content {
  position: relative;
  padding: 36px 40px 43px 40px;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  border-radius: 12px;
  text-align: center;
  width: 400px;
  margin: 0 auto;
  z-index: 2;
}

.form_logo {
  width: 196px;
  display: block;
  margin: 0 auto;
  margin-bottom: 14px;
}

@media screen and (max-width: 991px){
  .content {
    padding: 36px 30px 43px 30px;
  }
  .container {
    padding: 15px;
  }
  .container:after {
    width: 1190px;
    height: 1302px;
    top: 57px;
    left: 45px;
  }
}
