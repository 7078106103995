.login_social_title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  margin-bottom: 11px;
}

.login_social {
  display: flex;
  justify-content: center;
  margin-bottom: 11px;
}

.text_seporrator {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 14px;
}

.form_item input {
  margin-bottom: 10px;
  text-align: center;
}

.form_item input::-webkit-input-placeholder {
  opacity: 1;
}

.form_item input::-moz-placeholder {
  opacity: 1;
}

.form_item input:-moz-placeholder {
  opacity: 1;
}

.form_item input:-ms-input-placeholder {
  opacity: 1;
}

.two_form_items {
  display: flex;
  margin-bottom: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  align-items: center;
  justify-content: space-between;
}

.forgot_link {
  cursor: pointer;
}

.submit_btn {
  width: 100%;
}

.text_login {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: #4c6579;
  margin-top: 20px;
}

.text_login span {
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  color: #1974bb;
}

.text_login span:hover {
  text-decoration: underline;
}

.modal_form {
  width: 400px;
  padding: 36px 40px 43px 40px;
}

.form_logo {
  width: 196px;
  display: block;
  margin: 0 auto;
  margin-bottom: 14px;
}

.modal_form_title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  margin-bottom: 22px;
  text-align: center;
}

.modal_form_subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #4c6579;
  margin-bottom: 25px;
}
