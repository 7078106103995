.App {
  text-align: center;
}

#root {
  max-width: 1920px;
  display: block;
  margin: 0 auto;
  font-family: Roboto;
}

.body-container-app {
  display: flex;
  padding: 20px;
  position: relative;
  /* overflow-x: hidden; */
}

.enter_content {
  width: 100%;
  padding-top: 44px;
  padding-right: 43px;

  position: relative;

  padding-bottom: 40px;
}

.right_block {
  width: 32%;
}

.body_with_right_sidebar {
  display: flex;
  padding-bottom: 40px;
}

.body_with_right_sidebar .body_block {
  margin-right: 30px;
  width: 66%;
}

.hide {
  opacity: 0;
  transition: opacity 0.5s linear;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  -ms-transition: opacity 0.5s linear;
}

@media screen and (min-width: 1080px) and (max-width: 1620px) {
  .enter_content {
    padding-right: 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1259px) {
  .body-container-app {
    padding: 40px 47px;
    overflow-x: hidden;
    min-height: 100vh;
  }
  .enter_content {
    padding-right: 0;
    padding-top: 78px;
  }
  .body_with_right_sidebar .body_block {
    margin-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  .body_with_right_sidebar {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .right_block {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .body-container-app {
    padding: 20px 15px;
    overflow-x: hidden;
    display: block;
  }
  .enter_content {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .body_with_right_sidebar {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .body_with_right_sidebar .body_block {
    width: 100%;
    margin-right: 0;
  }
  .right_block {
    width: 100%;
  }
}
