.container {
  display: flex;
  align-items: center;
}

.container .i_checkbox__faux {
  background: #ffffff;
  border-radius: 8px;
  width: 26px;
  height: 26px;
}

.container .i_checkbox {
  margin-right: 10px;
}

.i_checkbox {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin-right: 17px;
}

.i_checkbox__real {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.i_checkbox__faux {
  width: 27px;
  height: 27px;
  display: block;
  background: #ffffff;
  position: relative;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  border-radius: 8px;
}

.i_checkbox__faux:before {
  width: 11px;
  height: 9px;
  top: 9px;
  left: 8px;
  background-image: url(../../assets/img/checked.svg);
  opacity: 0;
  content: '';
  display: block;
  position: absolute;
}

.i_checkbox__real:checked + .i_checkbox__faux:before,
.i_checkbox__real.checked + .i_checkbox__faux:before {
  opacity: 1;
}

.checkbox_text {
  cursor: pointer;
}
