.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* row-gap: 10px; */

  padding: 20px 30px;
  background: #f6f6fa;
  border-radius: 12px;
}

.container hr {
  width: 100%;
  background: #4c6579;
  opacity: 0.1;
}

.text {
  width: 100%;
  height: 80px;

  border: none;
  box-sizing: border-box;
  resize: none;

  color: #4c6579;
  opacity: 0.6;

  outline: none;

  background-color: #f6f6fa;
}

.style_block {
  display: flex;
  column-gap: 25px;
  align-items: center;
}

input[type='color'] {
  -webkit-appearance: none;
  border: none;
  border-radius: 6px;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 6px;
}

input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 6px;
}

.color {
  width: 22px;
  height: 22px;
  border: none;
  outline: none;
  padding: 0;
  background-color: inherit;
  border-radius: 6px;
}

.font_family {
  width: 180px;
}

.font_weight {
  width: 120px;
}

.font_size {
  width: 80px;
}
