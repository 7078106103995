.no_desctop {
  display: none !important;
}

.body_with_right_sidebar {
  display: flex;
}

.body_block {
  margin-right: 30px;
  max-width: 950px;
  flex-shrink: 0;
  width: 66%;
}

.video_block {
  background: #f6f6fa;
  border-radius: 12px;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.video_block .popup {
  position: fixed;
  top: initial;
  left: initial;
  right: 50px;
  bottom: 50px;
  max-width: 440px;
  max-height: 260px;
  border-radius: 12px;
  display: flex;
  text-align: center;
  overflow: hidden;
  position: fixed;
  outline: 0;
  transition: opacity 0.15s linear;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
}

.video_block_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.video_block_header_block {
  display: flex;
}

.back_step {
  width: 27px;
  height: 30px;
  border: none;
  background: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  outline: none;
}

.back_step svg {
  width: 27px;
  height: 24px;
  fill: #1974bb;
}

.video_block_title {
  margin-right: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  text-align: left;
  word-break: break-word;
}

.video_action_panel {
  display: flex;
  padding: 12px 24px;
  background: #ffffff;
  border-radius: 40px;
  margin-right: 20px;
}

.video_action_panel__item {
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  border-radius: 0;
  width: 16px;
  outline: none;
}

.video_action_panel__item:last-of-type {
  margin-right: 0;
}

.video_action_btn svg {
  width: 16px;
  height: 16px;
  fill: #4c6579;
}

.video_action_btn:hover svg {
  fill: #1974bb;
}

.set_thumbinal {
  padding: 12px 24px;
  background: #ffffff;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: none;
  color: #4c6579;
  outline: none;
  margin-right: 20px;
}

.set_thumbinal svg {
  width: 14px;
  height: 15px;
  fill: #4c6579;
  margin-right: 10px;
}

.set_thumbinal:hover svg {
  fill: #1974bb;
}

.set_thumbinal:hover {
  color: #1974bb;
}

.video_share_panel {
  display: flex;
  padding: 12px 24px;
  background: #ffffff;
  border-radius: 40px;
  align-items: center;
}

.video_share_title {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #4c6579;
  margin-right: 20px;
}

.social_share {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.social_share:last-of-type {
  margin-right: 0;
}

.social_share svg {
  fill: #ffffff;
}

.social_share.youtube {
  background: #f40000;
}

.social_share.youtube svg {
  width: 8px;
  height: 6px;
}

.social_share.youtube:hover {
  background: rgba(244, 0, 0, 0.81);
}

.social_share.facebook svg {
  width: 8px;
  height: 8px;
}

.social_share.facebook {
  background: #4e6297;
}

.social_share.facebook:hover {
  background: rgba(78, 98, 151, 0.81);
}

.video_action_ul {
  margin: 0;
  padding: 0;
  background: #f6f6fa;
  border-radius: 12px;
  padding: 25px 56px 22px 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;

  font-size: 14px;
}

.video_action_ul li {
  width: 29%;
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid rgba(76, 101, 121, 0.15);
  align-items: center;
}

.video_action_ul li a,
.video_action_ul li span {
  text-decoration: none;
  cursor: pointer;
  color: #4c6579;
}

.video_action_ul li a:hover,
.video_action_ul li span:hover {
  color: #1974bb;
}

.video_action_ul li:nth-child(7),
.video_action_ul li:nth-child(8),
.video_action_ul li:nth-child(9) {
  border-bottom: 0;
}

.video_action_ul li:before {
  content: '';
  background: url('../../assets/img/video-li.svg');
  width: 16px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.widget_embedding,
.player_configs {
  background: #f6f6fa;
  border-radius: 12px;

  padding: 22px 30px 30px 30px;

  text-align: left;
}

.widget_embedding__title {
  flex: none;
  order: 0;
  flex-grow: 0;

  margin-bottom: 8px;
}

.widget_embedding__info {
  font-size: 8px;
  line-height: 150%;

  color: #4c6579;
  opacity: 0.5;
}

.widget_embedding__inputs {
  margin: 17px 0 13px 0;

  display: flex;

  justify-content: space-between;
  column-gap: 10px;
}

.widget_embedding__inputs div:last-of-type {
  min-width: 143px;
}

.widget_embedding__inputs input {
  background-color: #ffffff;
  padding: 7px 20px;
  font-size: 14px;
  line-height: 20px;
}

.widget_embedding__inputs input::-webkit-input-placeholder {
  opacity: 0.6;
}
.widget_embedding__inputs input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.6;
}
.widget_embedding__inputs input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.6;
}
.widget_embedding__inputs input:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.6;
}

.widget_embedding__checkboxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.widget_embedding__checkboxes span {
  font-size: 14px;
  line-height: 15px;
}

.widget_embedding__btn {
  width: 58%;
}

.widget_embedding__btn button {
  font-size: 14px;
  line-height: 18px;
}

.player_configs {
  margin-top: 30px;
}

.video_edit_block {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.submenu_solo div:first-of-type {
  margin-right: 0;
}

.submenu_solo div > span {
  justify-content: left;
}

.submenu_solo div > span:after {
  position: absolute;
  right: 22px;
}
.submenu_solo div > span svg {
  position: relative;
  top: -2px;
}

@media screen and (min-width: 1080px) and (max-width: 1620px) {
  .widget_embedding,
  .player_configs {
    padding: 22px 20px 30px 20px;
  }

  .video_action_ul li {
    width: 33%;
  }
  .video_action_ul {
    padding: 15px;
  }
}

@media screen and (max-width: 1620px) {
  .video_block_header {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .video_block_header_block {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1259px) {
  .video_block {
    margin-bottom: 30px;
  }
  .video_edit_block {
    display: grid;
    grid-template-areas:
      'widget1 widget2'
      'widget1 widget3';
    grid-template-columns: repeat(2, 50%);
  }
  .widget_embedding {
    grid-area: widget2;
    margin-left: 15px;
  }
  .player_configs {
    grid-area: widget1;
    margin-top: 0;
    margin-right: 15px;
  }
}

@media screen and (max-width: 991px) {
  .no_mobile {
    display: none !important;
  }
  .no_desctop {
    display: block !important;
    order: 4;
  }
  .video_edit_block {
    display: flex;
    flex-direction: column;
  }
  .body_with_right_sidebar,
  .video_block_header,
  .video_block_header_block {
    display: block;
  }
  .video_share_title {
    display: none;
  }
  .video_block_header {
    position: relative;
  }
  .video_block_header_block.block_left {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-right: 96px;
  }
  .body_block {
    margin-right: 0;
  }
  .video_action_ul,
  .widget_embedding,
  .player_configs,
  .video_block {
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
    margin-bottom: 10px;
  }
  .video_block,
  .player_configs {
    padding: 30px 15px;
    margin-top: 0;
  }
  .video_action_ul,
  .widget_embedding {
    padding: 30px 15px;
    display: block;
  }
  .widget_embedding {
    order: 2;
  }
  .player_configs {
    order: 1;
  }

  .video_action_ul li,
  .widget_embedding__btn {
    width: 100%;
  }
  .video_action_panel {
    margin-right: 0;
    padding: 13px 32px;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .video_share_panel {
    position: absolute;
    top: -9px;
    right: 0;
    padding: 13px 18px;
  }
  .video_block_title {
    margin-right: 0;
  }
  .set_thumbinal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
