.container {
  text-align: initial;

  width: 737px;

  position: relative;
}

.video_block {
  position: relative;
  overflow: hidden;

  width: 100%;
  margin: auto;
}

.video {
  width: 100%;

  border-radius: 12px;
}

.text {
  position: absolute;

  left: 0;
  top: 0;

  background-color: inherit;

  cursor: move;

  width: 10px;

  white-space: pre;
}

.editor {
  margin-top: 20px;
}

.redirect_url {
  margin-top: 20px;
  margin-bottom: 30px;
}

.redirect_url div {
  min-width: 200px;
}

.code_block {
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;

  column-gap: 20px;
}

.code_block div {
  margin-right: 0;
}

.save_and_generate_code {
  margin-right: 14px;
}

.embed_text {
  padding: 12px 26px;

  background: #f6f6fa;
  border-radius: 40px;

  color: #4c6579;

  overflow-x: auto;
}

.embed_text div {
  overflow: hidden;
  white-space: nowrap;
}

.copy_btn {
  margin: auto;
}

.copy_btn svg {
  height: 20px;
  margin-right: 14px;
}
