.container {
  display: flex;
  column-gap: 25px;
  justify-content: space-between;
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.2ms;
}

.option label {
  display: flex;
}

.form_radio {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border: none;
  background: #f6f6fa;
  margin-right: 10px;
  outline: none;
  -webkit-appearance: none;
}

.form_radio:checked {
  background: linear-gradient(
      257.62deg,
      #43c3d7 -30.67%,
      rgba(67, 195, 215, 0) 102.58%
    ),
    #1974bb;
}

.form_radio:checked ~ .radio_checked {
  opacity: 1;
}

.radio_checked {
  width: 12px;
  height: 12px;
  background: #ffffff;
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  opacity: 0;
  border-radius: 100%;
}
