.container {
  /*padding: 0 31px 50px 31px;*/
  width: 736px;
}

.time_controls,
.gif_settings {
  display: flex;
  column-gap: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.gif_settings {
  margin-bottom: 30px;
}

.time_controls > div,
.gif_settings > div {
  flex: 1;

  flex-shrink: 0;
}

.time_controls > div > input {
  width: 100%;
  text-align: left;
}

.create_button {
  margin: 10px auto 0 auto;
}
