.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  position: relative;
}

.left {
  display: flex;
  margin: 0;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  color: #1974bb;
}

.user_menu {
  display: flex;
  align-items: center;
}

.search_button {
  padding-right: 20px;
  position: relative;
  border-right: 1px solid rgba(76, 101, 121, 0.1);
}

.search_button div {
  transform: translateX(100%);
  transition: 0.2s;
  opacity: 0;
  position: absolute;
  top: -13px;
  pointer-events: none;
}

.active_search div {
  transform: translateX(0);
  opacity: 1;
  position: static;
  pointer-events: auto;
}

.active_search .user_menu__ico_input {
  position: absolute;
  right: 38px;
  top: 13px;
  opacity: 0.3;
}

.active_search .user_menu__ico_input:hover {
  opacity: 1;
  fill: #1974bb;
}

.active_search .user_menu__ico_input,
.active_search .user_menu__ico_input svg {
  width: 12px;
  height: 12px;
  fill: #4c6579;
}

.search_button input {
  padding-left: 59px;
  max-width: 249px;
}

.user_profile {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.user_profile div {
  position: relative;
}

.user_profile .user_status {
  position: absolute;
  font-size: 0.75rem;
  color: #bb2c19;
  bottom: -70%;
  left: 0;
}

.search_button,
.logout_button {
  cursor: pointer;
}

.user_menu__ico {
  width: 16px;
  height: 16px;
}

.user_menu__ico svg {
  width: 16px;
  height: 16px;
  fill: #4c6579;
}

.search_button:hover .user_menu__ico svg,
.logout_button:hover .user_menu__ico svg {
  fill: #1974bb;
}

.mobile_submenu,
.mobile_header,
.mobile_menu {
  display: none;
}

@media screen and (min-width: 992px) and (max-width: 1629px) {
  .search_button div {
    top: -13px;
    min-width: 249px;
    right: calc(100% - 38px);
    position: absolute;
  }
  .active_search div {
    top: -24px;
  }
  .search_button input {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-right: 25px;
    border: 1px solid #80ace1;
  }
  .search_button .user_menu__ico_input {
    top: -11px;
    right: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1259px) {
  .container {
    position: static;
  }
  .user_block {
    position: absolute;
    right: 0;
    top: 0;
  }
  .mobile_header {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
  }
  .toggler_button {
    margin-right: 17px;
    order: 1;
  }
  .mobile_logo {
    order: 2;
    display: flex;
  }
  .mobile_logo img {
    width: 145px;
  }

  .mobile_menu .logout_button {
    display: none;
  }
  .left {
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (max-width: 1259px) {
  .toggler_button {
    width: 48px;
    height: 48px;
    display: block;
    padding: 0;
    border-radius: 100%;
    border: none;
    background: linear-gradient(
        257.62deg,
        #43c3d7 -30.67%,
        rgba(67, 195, 215, 0) 102.58%
      ),
      #1974bb;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  .toggler_button svg {
    width: 24px;
    height: 24px;
    fill: #ffffff;
  }
  .mobile_menu {
    display: block;
    position: fixed;
    width: 355px;
    height: 100%;
    background: radial-gradient(
        123.09% 40.07% at -29.26% -2.31%,
        #22a9e0 0%,
        rgba(34, 169, 224, 0) 100%
      ),
      linear-gradient(7.28deg, #43c3d7 -40.25%, rgba(67, 195, 215, 0) 105.8%),
      #1974bb;
    z-index: 9999;
    left: -15px;
    top: 0;
    transform: translateX(-100%);
    transition: 0.5s;
    padding: 45px 0 50px 48px;
    /*box-shadow: 20px 12px 24px rgba(33, 51, 65, 0.1);*/
  }
  .mobile_menu.active_menu {
    transform: translateX(0);
  }
  .back_menu {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    width: 40px;
    height: 40px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    opacity: 0;
    transition: 0.5s;
    position: absolute;
    right: -20px;
    top: 40px;
    opacity: 1;
  }
  .back_menu svg {
    width: 24px;
    height: 24px;
    transform: rotate(-180deg);
    fill: #1974bb;
  }
  .name_user,
  .avatar_user {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .name_user,
  .avatar_user {
    display: block;
  }
  .back_menu {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    background: transparent;
    position: static;
    opacity: 1;
  }
  .back_menu svg {
    fill: #ffffff;
  }
  .mobile_menu {
    width: calc(100% + 30px);
  }
  .toggler_button {
    width: 36px;
    height: 36px;
  }
  .toggler_button svg {
    width: 18px;
    height: 18px;
  }
  .user_profile,
  .logout_button {
    display: none;
  }
  .mobile_submenu {
    display: flex;
    width: 100%;
  }
  .mobile_submenu > div {
    width: 100%;
  }
  .mobile_submenu > div:first-of-type {
    margin-right: 11px;
  }
  .mobile_submenu > div > div > div > span {
    text-align: center;
  }
  .left {
    display: none;
  }
  .mobile_logo {
    width: 56%;
    max-width: 200px;
  }
  .mobile_logo img {
    width: 100%;
  }
  .mobile_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .mobile_menu_header,
  .mobile_menu_header_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile_menu_header {
    padding-bottom: 30px;
    padding-right: 40px;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 30px;
  }
  .name_user {
    color: #ffffff;
  }
  .logout_button {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #ffffff;
    display: flex;
    align-items: center;
    display: none;
  }
  .search_button {
    position: absolute;
    right: 47px;
    top: 4px;
    border-right: 1px solid rgba(76, 101, 121, 0.1);
    height: 36px;
  }
  .user_menu__ico {
    margin-right: 20px;
    width: 24px;
    height: 24px;
  }

  .user_menu__ico svg {
    width: 24px;
    height: 24px;
    fill: #ffffff;
  }

  .active_search {
    width: calc(100% + 10px);
    right: -5px;
    padding-right: 0;
    border-right: 0;
    top: -5px;
  }

  .search_button .user_menu__ico svg {
    fill: #4c6579;
  }

  .search_button .user_menu__ico {
    margin-right: 0;
    position: relative;
    top: 5px;
  }

  .active_search .user_menu__ico {
    position: static;
  }

  .search_button input {
    max-width: none;
    background: #ffffff;
    box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  }
  .notification {
    position: absolute;
    top: 0;
    right: 70px;
  }
}
