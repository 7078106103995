.container {
  width: 100%;
  margin-top: -50px;
  margin-bottom: -50px;
  margin-left: -32px;
  margin-right: -32px;
  width: calc(100% + 64px);
}

.files_progress {
  padding: 50px 40px 40px 40px;

  /*max-height: 230px;*/
  overflow-y: auto;
}


.drag_and_drop {
  position: relative;
  background: #e9e9e9;
}

.drag_and_drop__field {
  width: 100%;
  height: 89px;

  border: 2px dashed #222222;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  letter-spacing: -0.05px;

  color: rgba(0, 0, 0, 0.23);
}

.drag_and_drop__field span {
  color: #000000;
}

.drag_and_drop__add {
  width: 80px;
  height: 80px;

  background: #000000;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  position: absolute;
  right: 20px;
  top: -60px;
}

.drag_place {
  background: #f6f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;

  height: 240px;
  border-radius: 12px;
}

.drag_smart {
  height: 88px;
}

.drag_full {
  height: 240px;
}

.drag_ico {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.drag_ico svg {
  width: 20px;
  height: 20px;
  fill: #4c6579;
}

.drag_text {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: rgba(71, 101, 121, 0.3);
}

.drag_text span {
  color: #4c6579;
}
