blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
input,
pre,
td,
textarea,
th {
  margin: 0;
  padding: 0;
}

video {
  display: block;
}

* {
  box-sizing: border-box;
  max-height: 100000px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #4c6579;
}

select::-ms-expand {
  display: none;
}

.select {
  position: relative;
  display: flex;
  align-items: center;
}

.select:after {
  width: 8px;
  height: 6px;
  content: '';
  display: block;
  background-image: url('./assets/img/downarrow.svg');
  background-size: cover;
  margin-left: 10px;
}

body {
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  overflow-x: hidden;
  color: #4c6579;
}

p {
  margin: 19px 0;
}

input,
button {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.pagination-name {
  background-color: transparent !important;
  padding: 0 !important;
  margin-left: 10px;
}

.pagination-span {
  width: auto !important;
  margin-right: 10px;
}

.pagination-open {
  padding: 10px 20px !important;
  width: 65px !important;
  border-radius: 12px !important;
  top: 30px !important;
}

.pagination svg {
  stroke: #4C6579 !important;
}

.submit_change_email button:disabled,
.submit_change_email button:disabled:hover,
.submit_change_email button:disabled:active {
  border-color: rgba(76, 101, 121, .3);
  color: rgba(76, 101, 121, .3);
  background: none;
  opacity: 1;
}
