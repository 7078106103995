.wrap {
  background-color: #f6f6fa;
  border-radius: 12px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #4c6579;
  line-height: 18px;
  text-align: left;
  padding-bottom: 35px;
  margin-bottom: 30px;
}
.wrap button.active_button {
  color: #1974bb;
}
@media screen and (max-width: 1024px) {
  .wrap {
    border-radius: unset;
    width: 100vw;
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1024px) {
  .wrap {
    margin-left: -45px;
  }
}
