.container {
  /*padding: 50px 31px;*/
  width: 736px;
}

.save_crop_controls {
  display: flex;
  column-gap: 25px;
  margin-top: 30px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.save_button {
  margin: 30px auto 0 auto;
}

.timeline_controls {
  display: flex;
  width: 48%;
  justify-content: space-between;
}

@media screen and (max-width: 991px){
  .container {
    width: 100%;
  }
  .save_crop_controls {
    display: block;
  }
  .timeline_controls {
    justify-content: space-between;
    margin-bottom: 18px;
    width: 100%;
  }
  .timeline_controls input {
    max-width: 120px;
    text-align: center;
  }
}
