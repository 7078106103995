.container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.tumb {
  margin-right: 20px;
  width: 40px;
  height: 40px;

  background-color: black;
  border-radius: 10px;

  opacity: 0.4;
}

.uploaded {
  opacity: 1;
}
