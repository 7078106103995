.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: #4c6579;
  text-align: center;
}

.sub_title {
  align-self: flex-start;
}

.item {
  width: 100%;
}

.title,
.sub_title,
.item {
  margin-bottom: 20px;
}

.item:last-of-type {
  margin-bottom: 0;
}

.button {
  margin: auto;
}
