.container {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  padding-left: 13px;
}

.breadcrumb_item .home_icon {
  width: 16px;
  height: 16px;
  margin-right: 23px;
  fill: #1974bb;
}

.breadcrumb_item a,
.breadcrumb_item span {
  color: #4c6579;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 23px;
  display: flex;
  align-items: center;
}

.breadcrumb_item span {
  opacity: 0.3;
}

.breadcrumb_item span.breadcrumb_back {
  display: none;
}

.breadcrumb_item a {
  text-decoration: none;
  cursor:pointer
}

.breadcrumb_item a:hover {
  color: #1974bb;
}

.breadcrumb_separator {
  background: #4c6579;
  opacity: 0.2;
  transform: rotate(-25.77deg);
  width: 1px;
  height: 18px;
}

@media screen and (max-width: 991px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    background: #f6f6fa;
    border-radius: 40px;
  }
  .breadcrumb_separator,
  .breadcrumb_item {
    display: none;
  }
  .breadcrumb_item a,
  .breadcrumb_item span {
    margin: 0;
  }
  .breadcrumb_item:first-of-type,
  .breadcrumb_item:last-of-type {
    display: block;
  }
  .breadcrumb_item:first-of-type {
    order: 2;
  }
  .breadcrumb_item:last-of-type {
    order: 1;
    display: flex;
    align-items: center;
  }
  .breadcrumb_item .home_icon {
    order: 2;
    margin-right: 0;
    margin-left: 10px;
  }
  .breadcrumb_item span {
    opacity: 1;
  }
  .breadcrumb_item span.breadcrumb_back {
    margin-right: 13px;
    cursor: pointer;
  }
  .breadcrumb_back_icon {
    width: 18px;
    height: 16px;
    fill: #1974bb;
  }
  .breadcrumb_item span.breadcrumb_back {
    display: inline-flex;
  }
  .breadcrumb_item a,
  .breadcrumb_item span {
    font-size: 15px;
    line-height: 23px;
    font-weight: bold;
  }
}
