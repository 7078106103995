.selected {
  cursor: pointer;
  background-color: #fff;
  width: 27px;
  height: 27px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info_block {
  position: absolute;
  left: 0;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  background-color: #f6f6fa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected_row,
.info_block_select {
  background-color: #1974bb0d;
}

.info_container {
  display: none;
  padding: 8px 20px;
  max-width: 200px;
  min-height: 36px;
  max-height: 200px;
  background: #4c6579;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: -0.05px;
  position: absolute;
  top: 0;
  width: max-content;
  transform: translateY(-100%) translateY(-10px);
}

.info_block_text {
  overflow-x: hidden;
  overflow-y: auto;
}

.info_container:after {
  content: '';
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid #4c6579;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
}

.info_block:hover .info_container {
  display: block;
}

.bottom {
  display: none;
  white-space: nowrap;
  margin: 8px 0 0;
}

.bottom b {
  font-weight: bold;
  margin-right: 10px;
}

.mobile_table,
.info_block.info_mobile {
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1439px) {
  .desktop_hide {
    display: none;
  }
  .bottom {
    display: block;
  }
}
@media screen and (max-width: 1024px) {
  .mobile_hide {
    display: none;
  }
  .bottom {
    display: none;
  }
  .mobile_table {
    display: block;
    white-space: nowrap;
    font-weight: 500;
    font-size: 10px;
  }
  .mobile_table > tr > td {
    color: #4c6579;
    padding-left: inherit;
    padding-right: inherit;
    padding: 5px 20px 5px 0;
  }
  .mobile_table > tr > td + td {
    opacity: 0.7;
  }
  .info_block {
    display: none;
  }
  .info_block.info_mobile {
    position: relative;
    display: block;
  }
  .item_info {
    display: flex;
    justify-content: space-between;
  }
}
