.container {
  padding-top: 24px;
  padding-bottom: 11px;
  position:absolute;
  right: 63px;
  bottom: 11px;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.menu li {
  margin-right: 80px;
}

.menu li:last-of-type {
  margin-right: 0;
}

.menu li a {
  opacity: 0.3;
  text-decoration: none;
  color: #4c6579;
}

.menu li a:hover {
  opacity: 0.7;
}

@media screen and (min-width: 992px) and (max-width: 1259px) {
  .container {
    right: 47px;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1620px) {
  .container {
    right: 40px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    position: static;
  }
  .menu {
    justify-content: space-between;
  }

  .menu li {
    margin-right: 0;
  }
}
