.container_upload {
  /*padding: 40px 0;*/
  width: 320px;
}

.container_edit {
  /*padding: 50px 31px;*/
  width: 736px;
}

.header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #4c6579;
  margin-bottom: 22px;
  text-align: center;
}

.support_info {
  margin-bottom: 20px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.upload_button {
  margin: auto;
}

.info {
  margin-top: 20px;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.controls {
  display: flex;
  justify-content: space-between;

  margin-top: 30px;
}

@media screen and (max-width: 991px) {
   .container_upload {
     width: 100%;
   }
}
