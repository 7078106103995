.container {
  border: 1px solid rgba(25, 116, 187, 0.3);
  border-radius: 12px;
  padding: 19px 20px 19px 30px;
  margin-right: 1%;
  margin-bottom: 1.5%;
  width: 24%;
  max-width: 337px;
  cursor: pointer;
  transition: .2s;
  position: relative;
}

/* .container:nth-child(4n) {
  margin-right: 0;
} */

.container:hover {
  box-shadow: 0px 12px 24px rgba(33, 51, 65, 0.1);
  border-color: transparent;
}

.container:hover .ico {
  opacity: 0.2;
}

.container:hover .bottom {
  opacity: 0.7;
}

.container:active .remove_folder,
.remove_folder {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 100%;
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 2;
}

.container:hover .remove_folder {
  opacity: 1;
}

.container:active {
  background: linear-gradient(257.62deg,
      #43c3d7 -30.67%,
      rgba(67, 195, 215, 0) 102.58%),
    #1974bb;
  border: none;
  box-shadow: none;
}

.container:active .remove_folder,
.container:active .ico {
  opacity: 0;
}

.container:active .bottom {
  opacity: 1;
  color: #ffffff;
}

.container:active .title {
  color: #ffffff;
}

.title {
  font: normal 700 15px/23px 'Roboto';
  color: #1974BB;
  margin-bottom: 5px;
  text-align: left;
  position: relative;
  z-index: 2;
}

.ico {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  opacity: 0;
  transition: all 0.3s;
}

.show {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.ico svg {
  width: 12px;
  height: 12px;
  transition: all 0.3s;
}
.container:hover .ico:hover,
.container:active .ico:hover{
  opacity: 1;
}

.container:hover .ico:hover svg,
.container:active .ico:hover svg{
  fill: #1974BB;
}

.bottom {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.3;
}

.remove_folder svg {
  fill: #4c6579;
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 991px) {
  .container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    max-width: none;
  }
}
