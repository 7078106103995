.container {
  display: flex;
  width: 100%;

  align-items: center;
}

.name {
  margin-right: 30px;
}
