.fragment {
  position: absolute;
  width: 131px;
  height: 81px;

  z-index: 99;
  bottom: 39px;

  background-color: black;

  border-radius: 12px;
  border: 2px solid #ffffff;
}

.thumb {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 100%;
  top: -8px;
}
