.table_head th:nth-child(2) {
  min-width: 140px;
}
.selected {
  cursor: pointer;
  background-color: #fff;
  width: 27px;
  height: 27px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sortable_header {
  cursor: pointer;
}
.icon {
  padding-left: 10px;
  display: inline-block;
}
.icon svg {
  fill: transparent;
  stroke: #4c6579;
  transition: 0.5s all;
}
.icon.rotate svg {
  transform: rotate(180deg);
}
.icon.active svg {
  stroke: #1974bb;
}
.mobile_sort {
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1439px) {
  .desktop_hide {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .mobile_hide {
    display: none;
  }
  .mobile_header {
    display: flex;
    justify-content: space-between;
    line-height: 1.7rem;
  }
  .icon {
    margin-right: 20px;
  }
  .icon span {
    margin-right: 10px;
  }
  .mobile_sort {
    display: block;
    position: relative;
  }
  .mobile_sort_menu {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    background-color: #fff;
    z-index: 99;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 0;
    font-weight: 500;
  }
  .mobile_sort_item {
    list-style: none;
    padding: 20px 0;
    font-size: 14px;
  }
  .mobile_sort_item:not(:last-of-type) {
    border-bottom: 1px solid #4c65781a;
  }
}
