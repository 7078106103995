.container {
  row-gap: 10px;
  display: flex;
  flex-direction: column;

  width: 320px;
  height: 306px;
}

.back {
  display: flex;
  column-gap: 5px;
}

.date {
  width: 115px;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.btns > div {
  width: 155px;
}
