.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  background: #ffffff;
  border-radius: 40px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  opacity: 0.7;
}

.videos_count {
  padding-right: 20px;
  border-right: 1px solid rgba(76, 101, 121, 0.1);
}

.videos_show {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.videos_show .select {
  margin-left: 10px;
}

.videos_show .select:after {
  margin-left: 3px;
}

.right {
  background: #ffffff;
  border-radius: 40px;
  padding: 15px 20px;
  display: flex;
}

.prev_page,
.next_page {
  outline: none;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  cursor: pointer;
}

.prev_page {
  margin-right: 20px;
}

.prev_page svg {
  transform: scale(-1, -1);
}

.prev_page svg,
.next_page svg {
  width: 16px;
  height: 16px;
  fill: #4c6579;
}

.prev_page:hover svg,
.next_page:hover svg {
  fill: #1974bb;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

button:disabled:hover svg,
button[disabled]:hover svg {
  fill: #4c6579;
}

.mobile_pagination {
  display: none;
}

@media screen and (max-width: 991px) {
  .mobile_pagination {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
  .mobile_pagination button {
    padding: 12px 24px;
    text-align: center;
    background: none;
    font-weight: 500;
    border: 1px solid #1974bb;
    border-radius: 40px;
    width: 100%;
    color: #1974bb;
    outline: none;
  }
  .mobile_pagination button:hover {
    color: #43c3d7;
    border-color: #43c3d7;
  }
  .left {
    width: 100%;
  }
  .right {
    display: none;
  }
  .container {
    flex-direction: column;
  }
}
