.menu {
  margin: 0;
  list-style: none;
  display: flex;
  margin-right: 32px;
  padding: 12px 0;
  background: #f6f6fa;
  border-radius: 40px;
}

.menu:last-of-type {
  margin-right: 0;
}

.menu button {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #4c6579;
  cursor: pointer;
  padding: 0 24px;
  background: none;
  border: none;
  outline: none;
  height: 100%;
}

.menu li {
  border-right: 1px solid rgba(76, 101, 121, 0.1);
}

.menu li:last-of-type {
  border-right: 0;
}

.menu button:hover {
  color: #1974bb;
}

.menu_ico {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.menu_ico svg {
  width: 16px;
  height: 16px;
  fill: #4c6579;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu button:hover .menu_ico svg {
  fill: #1974bb;
}

@media screen and (min-width: 1025px) and (max-width: 1480px) {
  .menu button {
    font-size: 14px;
    padding: 0 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .menu button {
    font-size: 12px;
    line-height: 1em;
  }
}

@media screen and (max-width: 991px) {
  .menu {
    display: none;
  }
}
