.container {
  width: 100%;
  color: white;
  overflow: hidden;

  position: relative;
}

.video_label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #4c6579;
  padding: 0;
  /*border-bottom: 1px solid rgba(76, 101, 121, 0.1);
  border-top: 1px solid rgba(76, 101, 121, 0.1);*/
  position: relative;
}

.video_label:before,
.video_label:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(76, 101, 121, 0.1);
}

.video_label:before {
  top: 0;
  opacity: 0;
}

.video_label:after {
  bottom: 0;
}

.video_active .video_label:before,
.video_active .video_label:after {
  background: #1974bb;
  opacity: 0.4;
}

.video_label_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 20px 0;
}

.video_label_selected {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.video_label_thumb {
  display: none;
  opacity: 0;
  border-radius: 12px;
  margin-right: 30px;
  transition: 0.2ms;
  position: relative;
}

.video_active .video_label_thumb {
  opacity: 1;
  display: flex;

  z-index: 2;
}

.video_active .video_label_thumb video {
  opacity: 1;
  display: flex;

  width: 214px;
  height: 144px;

  border-radius: 12px;
  background-color: black;
}

.video_active .video_label_thumb:after {
  width: 44px;
  height: 44px;
  content: '';
  background: url('../../../assets/img/play-button.svg');
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-position: 50%;
  position: absolute;

  z-index: 2;
}

.video_active .video_label {
  /*border-color: rgba(25, 116, 187, 0.4);*/

  opacity: 1;
  display: flex;
  bottom: 0;
  left: 0;
}

.video_active {
  position: relative;
  top: -1px;
}

.video_label_left {
  display: flex;
  /*width: 701px;*/
  width: 53%;
  align-items: center;
  margin-right: 1%;
}

.video_label_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 46%;
}

.video_active .video_label_left {
  align-items: normal;
}

.video_label_size,
.video_label_date {
  opacity: 0.7;
}

.video_label_type {
  display: flex;
  flex-direction: column;
}

.video_label_type__load {
  opacity: 0.3;
}

.video_label_type__ready {
  opacity: 1;
}

.video_label_modify {
  display: flex;
  position: relative;
}

.video_label__name {
  text-decoration: none;
  color: #4c6579;
}

.video_active .video_label__name {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.video_label__panel {
  display: none;
  opacity: 0;
  transition: 0.2ms;
  background: #ffffff;
  border-radius: 40px;
  padding: 12px 24px;
  align-items: center;
}

.video_active .video_label__panel {
  opacity: 1;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.video_label_panel__item {
  position: relative;
  z-index: 2;
  display: flex;
  margin-right: 15px;
}

.video_label_panel__item:last-of-type {
  margin-right: 0;
}

.video_edit_btn {
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
}

.video_edit_btn svg {
  fill: #4c6579;
}

.video_edit_btn:hover svg {
  fill: #1974bb;
}

.video_checkbox {
  width: 44px;
}

.video_checkbox span {
  position: absolute;
  top: 15px;
  left: 0;
}

.video_active .video_checkbox span {
  top: 10px;
}

@media screen and (max-width: 991px) {
  .video_active .video_label__name {
    font-size: 15px;
    line-height: 23px;
  }
  .video_label_right {
    width: 100%;
  }
  .video_label_container {
    flex-wrap: wrap;
  }
  .video_label_left {
    width: 100%;
    margin-bottom: 9px;
    margin-right: 0;
  }
  .video_label_size,
  .video_label_date {
    font-size: 10px;
    line-height: 15px;
  }
  .video_active .video_label_size,
  .video_active .video_label_date {
    position: absolute;
    top: 65px;
  }
  .video_label_size {
    left: 45px;
    position: relative;
  }
  .video_label_date {
    right: 0;
  }
  .video_active .video_label_left {
    flex-direction: column;
    margin-bottom: 0;
    margin-right: 0;
  }
  .video_active .video_label_modify {
    order: 1;
    position: static;
    margin-bottom: 40px;
  }
  .video_label_modify {
    padding-left: 45px;
  }
  .video_active .video_label_thumb {
    order: 2;
    margin-right: 0;
  }
  .video_active .video_label_thumb img {
    width: 100%;
    height: 100%;
  }
  .video_active .video_label_container {
    padding-top: 25px;
    padding-bottom: 80px;
  }
  .video_active .video_label__panel {
    width: 100%;
    bottom: 20px;
    justify-content: space-between;
    padding: 12px 58px;
  }

  .video_label .video_checkbox {
    width: 45px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
  }

  .video_label .video_checkbox span {
    position: absolute;
    top: 12px;
  }

  .video_active .video_label .video_checkbox span {
    top: 14px;
  }

  .video_label_type {
    display: none;
  }
}
